import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { PROJECTS } from 'constants/projects';
import { Grid } from 'components/Grid';
import { ProjectMainLayout } from 'layouts/ProjectMainLayout';

export const SarminView = () => {
    const images = useStaticQuery(graphql`
        query SarminImages {
            main: file(name: { eq: "sarmin-main" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
            lower: file(name: { eq: "sarmin-lower" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
            upper: file(name: { eq: "sarmin-upper" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
        }
    `);

    return (
        <ProjectMainLayout projectName={PROJECTS.sarmin.name}>
            <Grid {...images} projectName={PROJECTS.sarmin.name} />
        </ProjectMainLayout>
    );
};
